import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { BoldText } from '../../components/bold-text/bold-text';
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Grid_Layout"
      }}>{`CSS Grid Layout`}</a>{` is a two-dimensional grid-based
layout system for websites. It makes it easy to arrange content in rows and columns and create responsive layouts.`}</p>
    <p>{`If you're familiar with grid layouts, you've probably encountered the following issue: A grid container can
be placed inside another grid to create a `}<a parentName="p" {...{
        "href": "https://gridbyexample.com/examples/example21/"
      }}>{`nested grid layout`}</a>{`.
Unfortunately, the content of the child grid doesn't automatically align with the parent grid. But don't worry!
We can solve this issue with the `}<InlineCode mdxType="InlineCode">{`subgrid`}</InlineCode>{` feature!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABUai0VDULj//EABkQAQEBAQEBAAAAAAAAAAAAAAECAwAREv/aAAgBAQABBQIzfkjMhselrTpzPJgk/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/ARLW/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPwGbY//EABoQAAMBAAMAAAAAAAAAAAAAAAABEQIQIUH/2gAIAQEABj8Cvg9N2F0nSafXEP/EABwQAAICAgMAAAAAAAAAAAAAAAABESExQVFxof/aAAgBAQABPyFxHVds6QUQLCHMC1XWoHXivRJyP//aAAwDAQACAAMAAAAQ1w//xAAWEQEBAQAAAAAAAAAAAAAAAAAAAVH/2gAIAQMBAT8QhaV//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERUf/aAAgBAgEBPxBGJg//xAAfEAEAAgEDBQAAAAAAAAAAAAABABEhMUFhgaHB4fD/2gAIAQEAAT8QqzqqKFVrDWgWhDp49xtHZIwcASyDjQDBt2jISzWT5piWEab5J//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A chessboard with a grid of rows and columns.",
          "title": "A chessboard with a grid of rows and columns.",
          "src": "/static/ce426dbc3bdbc129d8393283007c4456/e5166/pexels-rodnae-productions-chess-board.jpg",
          "srcSet": ["/static/ce426dbc3bdbc129d8393283007c4456/f93b5/pexels-rodnae-productions-chess-board.jpg 300w", "/static/ce426dbc3bdbc129d8393283007c4456/b4294/pexels-rodnae-productions-chess-board.jpg 600w", "/static/ce426dbc3bdbc129d8393283007c4456/e5166/pexels-rodnae-productions-chess-board.jpg 1200w", "/static/ce426dbc3bdbc129d8393283007c4456/b17f8/pexels-rodnae-productions-chess-board.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © RODNAE Productions / pexels.com`}</em></p>
    <p>{`The subgrid feature makes grid layout even more powerful and enables us to create perfectly aligned layouts.
I've prepared a demo of a typical use case. Let's see how it all works.`}</p>
    <h2>{`The Basics of Grid Layout`}</h2>
    <p>{`The grid layout system is defined in the `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-grid-2/"
      }}>{`CSS Grid Layout Module`}</a>{` specification:`}</p>
    <blockquote>
      <p parentName="blockquote">{`A `}<BoldText mdxType="BoldText">{`two-dimensional grid-based layout system`}</BoldText>{`, optimized for user interface design. In the grid layout model,
the children of a grid container can be positioned into arbitrary slots in a predefined flexible or fixed-size layout grid.`}</p>
    </blockquote>
    <p>{`You can turn an HTML element into a grid container with the CSS property `}<InlineCode mdxType="InlineCode">{`display: grid`}</InlineCode>{`. Next, you can
define the rows and columns with the properties `}<InlineCode mdxType="InlineCode">{`grid-template-rows`}</InlineCode>{`
and `}<InlineCode mdxType="InlineCode">{`grid-template-columns`}</InlineCode>{`.`}</p>
    <p>{`There are many ways to define a grid and populate it with content. Listing all possible settings and scenarios would go beyond
the scope of this article. If you want to learn more, I can recommend the tutorials
`}<a parentName="p" {...{
        "href": "https://css-tricks.com/snippets/css/complete-guide-grid/"
      }}>{`“A Complete Guide to CSS Grid”`}</a>{` and
`}<a parentName="p" {...{
        "href": "https://learncssgrid.com/"
      }}>{`“Learn CSS Grid”`}</a>{`.`}</p>
    <h2>{`What is Subgrid and What's it Good for?`}</h2>
    <p>{`The subgrid feature was added in `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-grid-2/#subgrids"
      }}>{`CSS Grid Layout Module Level 2`}</a>{`.
The specification states:`}</p>
    <blockquote>
      <p parentName="blockquote">{`A nested grid can defer the definition of its rows and/or columns to its parent grid container, making it a subgrid.
In this case, the grid items of the subgrid participate in sizing the parent grid, allowing the contents of both grids to align.`}</p>
    </blockquote>
    <p>{`To define a subgrid, you simply set the value `}<InlineCode mdxType="InlineCode">{`subgrid`}</InlineCode>{` for the
property `}<InlineCode mdxType="InlineCode">{`grid-template-rows`}</InlineCode>{` or `}<InlineCode mdxType="InlineCode">{`grid-template-columns`}</InlineCode>{`. That's it! The
nested grid will now use the columns and/or rows defined by the parent grid and align its content accordingly.`}</p>
    <h2>{`Demo: Punk API Beer List`}</h2>
    <p>{`I've created a `}<a parentName="p" {...{
        "href": "https://github.com/alexlehner86/punk-api-beer-list"
      }}>{`React demo`}</a>{` that displays a list of beers in a grid with
three equally sized columns. Each grid cell contains information about a specific beer. Check out
the `}<a parentName="p" {...{
        "href": "https://alexlehner86.github.io/punk-api-beer-list/"
      }}>{`live version here`}</a>{` (best viewed on a desktop screen).`}</p>
    <iframe src="https://alexlehner86.github.io/punk-api-beer-list/" title="Demo Page: Beer List with Subgrid Feature" loading="lazy"></iframe>
    <h3>{`The Parent Grid Container`}</h3>
    <p>{`The demo uses an unordered list as the parent grid container. Here's the `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/introducing-jsx.html"
      }}>{`JSX code`}</a>{`:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<ul className={styles.beerGrid}>
    {items.map(item => (
        <li key={item.id}>
            <BeerItemDetails item={item} />
        </li>
    ))}
</ul>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`In my CSS code, I use the `}<InlineCode mdxType="InlineCode">{`repeat`}</InlineCode>{` and `}<InlineCode mdxType="InlineCode">{`minmax`}</InlineCode>{` functions to create a grid
with three columns and a variable number of rows. Each column is equally sized with a minimum width of `}<InlineCode mdxType="InlineCode">{`15rem`}</InlineCode>{`.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`ul.beerGrid {
    display: grid;
    gap: 2.5rem;
    grid-template-columns: repeat(3, minmax(15rem, 1fr));
    grid-template-rows: auto;
    list-style: none;
}`}</code>{`
        `}</deckgo-highlight-code>
    <h3>{`Turning the Beer Content Cards into Subgrids`}</h3>
    <p>{`Each list item is rendered using the `}<InlineCode mdxType="InlineCode">{`BeerItemDetails`}</InlineCode>{` React component. This component
consists of a header section (`}<InlineCode mdxType="InlineCode">{`hgroup`}</InlineCode>{` element) and a details section
(`}<InlineCode mdxType="InlineCode">{`div`}</InlineCode>{` element):`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<hgroup className={styles.heading}>
    <h2>{item.name}</h2>
    <p>{item.tagline}</p>
</hgroup>
<div className={styles.flexRow}>
    <!-- details -->
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`In my CSS code, I define the list items as grid containers with two rows (`}<InlineCode mdxType="InlineCode">{`grid-row: span 2`}</InlineCode>{`).
The `}<InlineCode mdxType="InlineCode">{`grid-template-rows: subgrid`}</InlineCode>{` declaration tells the browser to align the header and details
section of each beer card that is placed on the same row.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`ul.beerGrid li {
    display: grid;
    grid-template-rows: subgrid;
    grid-row: span 2;
    gap: 0;
}`}</code>{`
        `}</deckgo-highlight-code>
    <h3>{`Easier Development with CSS Grid Inspector`}</h3>
    <p>{`I made heavy use of the `}<a parentName="p" {...{
        "href": "https://firefox-source-docs.mozilla.org/devtools-user/page_inspector/how_to/examine_grid_layouts/"
      }}>{`CSS Grid Inspector`}</a>{`
that is part of Firefox DevTools. This awesome tool highlights your grid's rows and columns and makes it easier to understand
how the parent grid and its subgrids fit together.`}</p>
    <p>{`The Chrome DevTools offer a `}<a parentName="p" {...{
        "href": "https://developer.chrome.com/docs/devtools/css/grid/"
      }}>{`similar tool`}</a>{`. But as a passionate Firefox user, I just
had to mention the Firefox tool first. 😉`}</p>
    <h2>{`Browser Support and Progressive Enhancement`}</h2>
    <p>{`At the time of publication of this post, only Safari and Firefox `}<a parentName="p" {...{
        "href": "https://caniuse.com/css-subgrid"
      }}>{`support the subgrid feature`}</a>{`.
But don't worry! You don't have to wait for Chrome and Edge to catch up.`}</p>
    <p>{`Apply the principle of `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Progressive_enhancement"
      }}>{`progressive enhancement`}</a>{` to provide a basic layout and
also take advantage of the subgrid feature in supporting browsers. To achieve this, we use `}<InlineCode mdxType="InlineCode">{`@supports`}</InlineCode>{` to check
browser support before we set the `}<InlineCode mdxType="InlineCode">{`subgrid`}</InlineCode>{` value:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@supports (grid-template-rows: subgrid) {
    ul.beerGrid li {
        display: grid;
        grid-template-rows: subgrid;
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Useful Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/css-grid-2/"
        }}>{`CSS Grid Layout Module Level 2 (specification)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://css-tricks.com/snippets/css/complete-guide-grid/"
        }}>{`“A Complete Guide to CSS Grid” (CSS Tricks)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://gridbyexample.com/"
        }}>{`“Grid by Example” (Rachel Andrew)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://learncssgrid.com/"
        }}>{`“Learn CSS Grid” (Jonathan Suh)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ishadeed.com/article/learn-css-subgrid/"
        }}>{`“Learn CSS Subgrid” (Ahmad Shadeed)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      